import React from 'react'
import Accordion from '../Accordion'
import useFaqTexts from './useFaqTexts'

const FaqSection = ({ id = 'faq-section', useDefaultIndex }) => {
  const { firstSection, secondSection } = useFaqTexts()
  return (
    <div id={id} className="px-4 lg:px-0 mt-4 lg:mt-0">
      <Accordion
        useDefaultIndex={useDefaultIndex}
        className="my-16"
        title="Hinweise zum nextbike-Gutschein"
        items={firstSection}
      />
      <Accordion
        useDefaultIndex={useDefaultIndex}
        className="my-16"
        title="Hinweise und Infos zu unseren Young-Tarifen"
        items={secondSection}
      />
    </div>
  )
}

export default FaqSection
