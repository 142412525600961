import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import FaqSection from '../components/FaqSection'
import CookieBanner from '../components/CookieBanner'
import SEO from '../components/seo'

const DefaultLayout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <SEO />
      <Navbar />
      <main className="flex-1 relative">
        <TopShadowHack />
        {children}
      </main>
      <FaqSection useDefaultIndex />
      <Footer />
      <CookieBanner />
    </div>
  )
}

export default DefaultLayout

export const TopShadowHack = () => (
  <div
    style={{
      position: 'absolute',
      width: '100%',
      height: 5,
      top: 0,
      left: 0,
      zIndex: 1,
      boxShadow: 'inset 0px 2px 5px -2px rgba(0,0,0,0.35)',
    }}
  />
)
