import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

const Footer = () => {
  const links = [
    { text: 'Impressum, AGB & Datenschutz', href: null, link: 'imprint', external: false },
  ]
  const detailText = '© 2020 Vodafone GmbH'
  return (
    <StaticQuery
      query={graphql`
        query VodafoneLogoFooter {
          vodafoneLogo: imageSharp(
            original: { src: { regex: "/^.*vodafone-logo-with-text.*$/" } }
          ) {
            fixed(pngQuality: 8, background: "#121212") {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      `}
      render={({ vodafoneLogo: { fixed: vodafoneLogo } }) => (
        <footer className="bg-secondary-black text-white py-6 border-b-12 border-primary">
          <div className="container mx-auto px-4 lg:px-0 lg:flex-no-wrap flex-wrap">
            <div className="flex flex-wrap">
              <Img fixed={vodafoneLogo} />
              <div className="flex-1 text-sm text-right">
                in Kooperation mit
                <br />
                <span className="block font-bold text-2xl">nextbike</span>
              </div>
              <div className="w-full"></div>
              <nav className="flex -mx-6 mb-6 mt-6 sm:mt-0">
                {links.map(({ text, external, link, ...attrs }) => {
                  if (external) {
                    return (
                      <a key={text} {...attrs} className="mx-3 px-3 hover:text-primary">
                        {text}
                      </a>
                    )
                  } else {
                    return (
                      <Link key={text} className="mx-3 px-3 hover:text-primary" to={link}>
                        {text}
                      </Link>
                    )
                  }
                })}
              </nav>
              <p className="w-full text-sm">{detailText}</p>
            </div>
          </div>
        </footer>
      )}
    />
  )
}

export default Footer
