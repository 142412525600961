import React, { useState, useEffect } from 'react'

const CookieBanner = () => {
  const [hasAcceptedCookies, setHasAcceptedCookies] = useState(true)
  useEffect(() => {
    if (typeof window !== `undefined`)
      setHasAcceptedCookies(localStorage.getItem('hasAcceptedCookies'))
  }, [setHasAcceptedCookies])

  return !hasAcceptedCookies ? (
    <figure className="fixed bottom-0 left-0 ml-6 mb-16 bg-black text-white max-w-md z-20 overflow-hidden rounded shadow-2xl">
      <h4 className="p-6">
        Diese Webseite verwendet Cookies, um Dir die beste Funktionialität zu garantieren.
      </h4>
      <button
        className="block bg-primary w-full p-2"
        type="button"
        onClick={() => {
          localStorage.setItem('hasAcceptedCookies', true)
          setHasAcceptedCookies(true)
        }}
      >
        Verstanden
      </button>
    </figure>
  ) : (
    <></>
  )
}

export default CookieBanner
