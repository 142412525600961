import React from 'react'
import Helmet from 'react-helmet'
import { Location } from '@reach/router'
import favico from '../images/favicon.ico'

function SEO({
  description = 'Entdecke Handytarife, Internet und TV im ausgezeichneten Vodafone Netz! Jetzt Handy + Tarif auswählen & online zusätzlich sparen!',
  lang = 'de',
  keywords = ['Mobilfunk', 'Internet', 'Festnetz', 'Telefon', 'TV', 'Kombi-Pakete', 'GigaKombi'],
  title = 'nextbike | Friyaydeals',
}) {
  return (
    <Location>
      {({ location }) => {
        return (
          <Helmet
            htmlAttributes={{ lang }}
            title={title}
            link={[
              {
                rel: 'shortcut icon',
                type: 'image/png',
                href: `${favico}`,
              },
            ]}
            meta={[
              {
                name: `author`,
                content: 'Vodafone GmbH',
              },
              {
                name: `generator`,
                content: 'Gatsby',
              },
              {
                name: `description`,
                content: description,
              },
              {
                property: `og:title`,
                content: title,
              },
              {
                property: `og:url`,
                content: location.href,
              },
              {
                property: `og:type`,
                content: 'website ',
              },
              {
                property: `og:image`,
                content: 'https://www.vodafone.de/media/img/common/brand-red.png ',
              },
              {
                name: `lang`,
                content: 'de',
              },
              {
                name: `robots`,
                content: 'index,follow',
              },
              {
                name: `searchTitle`,
                content: 'Vodafone.de | Mobilfunk, Handys & Internet-Anbieter',
              },
            ].concat(
              keywords?.length > 0
                ? {
                    name: `keywords`,
                    content: keywords.join(`, `),
                  }
                : []
            )}
          />
        )
      }}
    </Location>
  )
}

export default SEO
