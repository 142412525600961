import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

const Navbar = () => {
  return (
    <StaticQuery
      query={graphql`
        query VodafoneLogo {
          vodafoneLogo: imageSharp(original: { src: { regex: "/^.*vodafone((?!text).)*$/" } }) {
            fixed(width: 50, height: 50) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
          increasingSquares: imageSharp(
            original: { src: { regex: "/.*increasing-squares-full.*/" } }
          ) {
            fluid(maxWidth: 500, traceSVG: { color: "rgba(252, 228, 227, 0.5)" }) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          nextbikeLogo: imageSharp(original: { src: { regex: "/.*nextbike*./" } }) {
            fixed(width: 60, height: 60) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      `}
      render={({
        vodafoneLogo: { fixed: vodafoneLogo },
        increasingSquares: { fluid: increasingSquares },
        nextbikeLogo: { fixed: nextbikeLogo },
      }) => (
        <nav className="flex items-stretch justify-between max-h-22">
          <Link className="flex items-center flex-shrink-0 ml-16" to="/">
            <Img fixed={vodafoneLogo} alt="Vodafone logo" />
              <span className="block ml-4 pl-4 font-bold text-3xl leading-none border-l border-black">
                &nbsp;
            </span>
              <Img fixed={nextbikeLogo} alt="Nextbike logo" />
          </Link>
          <div className="w-1/2 max-w-xl">
            <Img
              className="max-h-full"
              fluid={increasingSquares}
              alt="Increasing squares"
              objectPosition="50% 54%"
            />
          </div>
        </nav>
      )}
    />
  )
}

export default Navbar
