import React from 'react'
import ChevronDown from './icons/ChevronDown'
import style from './Accordion.module.css'

const Accordion = ({ title, items, className, useDefaultIndex }) => {
  return (
    <div className={`${className} container mx-auto`}>
      <h3 className="text-2xl font-semibold ">{title}</h3>
      {items.map(({ title, contents, headerFootNote }, i) => (
        <div
          key={i}
          id={useDefaultIndex && headerFootNote && `footnote-${headerFootNote}`}
          className="my-4 mb-6"
        >
          <input
            type="checkbox"
            id={`checkbox-${title.slice(-5)}-${i}-${useDefaultIndex}`}
            className={`${style.input}`}
          />
          <label
            htmlFor={`checkbox-${title.slice(-5)}-${i}-${useDefaultIndex}`}
            className="flex-between-center p-2 text-xl md:text-2xl border border-gray-400 select-none cursor-pointer"
          >
            <h3 className="font-normal">
              {title}
              {headerFootNote && <sup>{headerFootNote}</sup>}
            </h3>
            <ChevronDown className={`${style.icon} text-gray-700`} />
          </label>
          <section className={`${style.content} text-base`}>
            {contents.map((content, i) => (
              <React.Fragment key={content.header}>
                <h4 className={`text-base md:text-lg font-semibold ${i ? 'my-6' : 'mb-6'}`}>
                  {content.header}
                  {content.headerFootNote && <sup>{content.headerFootNote}</sup>}
                </h4>
                {Array.isArray(content.text) ? (
                  content.text.map((text, i) => (
                    <p
                      key={text.slice(0, 10) + i}
                      className="text-sm md:text-base my-3"
                      dangerouslySetInnerHTML={{ __html: text }}
                    />
                  ))
                ) : (
                  <p
                    className="text-sm md:text-base"
                    dangerouslySetInnerHTML={{ __html: content.text }}
                  />
                )}
              </React.Fragment>
            ))}
          </section>
        </div>
      ))}
    </div>
  )
}

export default Accordion
